//<!-- Global JS -->
//<script src="node_modules/jquery/dist/jquery.min.js"></script>
//<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCnKt8_N4-FKOnhI_pSaDL7g_g-XI1-R9E"></script>

// Plugins JS
// note the function usage! for dynamic import asyncronically
import('jquery-parallax')
import('highlight.js')

// Theme JS
require("./incline/animation")
require("./incline/alert")
require("./incline/code")
require("./incline/dropdown")
require("./incline/faq")
require("./incline/fullpage")
//require("./incline/google-maps")
require("./incline/openstreetmap")
require("./incline/modal")
require("./incline/navbar")
require("./incline/newsletter")
require("./incline/polyfill")
require("./incline/slider")
require("./incline/testimonials")
//require("./incline/year")
