//
// openstreetmap.js
// Theme module
//
import L from 'leaflet'

// import icon images and let require handlers emit those files to public pack path
// we get a reference here, so we can use that for configuration
const iconUrl       = require('../../../../node_modules/leaflet/dist/images/marker-icon.png')
const iconRetinaUrl = require('../../../../node_modules/leaflet/dist/images/marker-icon-2x.png')
const shadowUrl     = require('../../../../node_modules/leaflet/dist/images/marker-shadow.png')

'use strict';

(function() {
  
  // Variables
  // =========

  var maps = document.querySelectorAll('.map-container');
  
  // In order to customize the default icon, just change the properties of `L.Icon.Default.prototype.options`
  // (which is a set of `Icon options`).
  // we need to do that for compatibility with webpack, as url-loader rewrites the image urls
  // and auto-guessing by Leaflet fails
  L.Icon.Default.prototype.options.imagePath     = '/.';
  L.Icon.Default.prototype.options.iconUrl       = iconUrl.default;
  L.Icon.Default.prototype.options.iconRetinaUrl = iconRetinaUrl.default;
  L.Icon.Default.prototype.options.shadowUrl     = shadowUrl.default;

  // Methods
  // =======

  function initMap(elem) {

    // Get map data
    var zoom = parseInt(elem.getAttribute('data-zoom'));
    var markers = JSON.parse(elem.getAttribute('data-markers'));
    //var styles = [{"featureType":"all","elementType":"geometry.fill","stylers":[{"weight":"2.00"}]},{"featureType":"all","elementType":"geometry.stroke","stylers":[{"color":"#9c9c9c"}]},{"featureType":"all","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#eeeeee"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#7b7b7b"}]},{"featureType":"road","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#46bcec"},{"visibility":"on"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#c8d7d4"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#070707"}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"color":"#ffffff"}]}];

    // Init map
    // Create Leaflet map on map element.
    var map = L.map(elem);
    // Add OSM tile leayer to the Leaflet map.
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
    }).addTo(map);
    // Set map's center to first target with zoom
    var center = L.latLng(markers[0][0],markers[0][1]);
    map.setView(center, zoom);
    
    //var map = new google.maps.Map(elem, {
    //  center: center,
    //  styles: styles,
    //  zoom: zoom
    //});

    // Get bounds
    //var bounds = new google.maps.LatLngBounds();

    // Create markers
    markers.forEach(function(item, i, arr) {
      // Target's GPS coordinates.
      var position = L.latLng(item[0],item[1]);
      // Place a marker on the same location.
      L.marker(position).addTo(map);

      // Extend bounds
      //bounds.extend(position);
    });

    // Fit markers into bounds
    //if ( !zoom ) {
    //  map.fitBounds(bounds);
    //}
  }

  // Events
  // ======

  // Init map

  if ( maps ) {
    [].forEach.call(maps, function(elem) {
      initMap(elem);
    });
  }

})();