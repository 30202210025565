/*
 * https://select2.org/configuration/data-attributes
 *
 *   data-* attributes
 * It is recommended that you declare your configuration options by passing
 * in an object when initializing Select2. However, you may also define your
 * configuration options by using the HTML5 data-* attributes, which will
 * override any options set when initializing Select2 and any defaults.
 *
 *   camelCase options
 * HTML data attributes are case-insensitive, so any options which contain
 * capital letters will be parsed as if they were all lowercase. Because
 * Select2 has many options which are camelCase, where words are separated
 * by uppercase letters, you must write these options out with dashes instead.
 * So an option that would normally be called allowClear should instead
 * be defined as allow-clear.
 */

import $ from 'jquery'

'use strict';

// do not import jquery to $
// onmount is bound to $ so importing jquery to $ would change scope of $
// and we would loose onmount
import 'select2/src/js/jquery.select2'

// trick select2 language auto loading feature by priming the cache first
// select2 is not very fit in combination with webpack
import EnglishTranslation from 'select2/src/js/select2/i18n/en'
import GermanTranslation from 'select2/src/js/select2/i18n/de'
import Translation from 'select2/src/js/select2/translation'

Translation._cache['./i18n/en'] = EnglishTranslation;
Translation._cache['en'] = EnglishTranslation;
Translation._cache['./i18n/de'] = GermanTranslation;
Translation._cache['de'] = GermanTranslation;

$.fn.select2.defaults.set('debug', true); // This will help you debug problem with translations
$.fn.select2.defaults.set('language', 'de');

(function() {

  const initializeSelect2 = function () {
    $('.js-default-select2-init').select2({
      theme: 'bootstrap4'
    })
    $('.js-autocomplete-select2-init').each(function() {
      let $this = $(this),
          url = $this.data('url')
      $this.select2({
        theme: 'bootstrap4',
        tags: true,
        tokenSeparators: [','],
        minimumInputLength: 1,
        ajax: {
          url: url,
          dataType: 'json',
          data:    function(term) { return { term: term } },
          results: function(data) { return { results: data } }
        }
      })
    })
  }

  'ready show.bs page:change turbolinks:load siteware:domchanged'.split(' ').forEach((ev) => {
    document.addEventListener(ev, initializeSelect2)
  })

})()
