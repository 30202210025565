// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// note the function usage! for dynamic import asyncronically
import('popper.js')
import('../src/bootstrap-custom')
import('bootstrap-validator')
import moment from 'moment'
// Make moment globally available
window.moment = moment
import 'moment-timezone/builds/moment-timezone-with-data'

// import vanilla-nested for nested forms
import 'vanilla-nested'

// start incline bootstrap theme set
require('../src/incline')

// It's not necessary to grab a reference to the imported variable because we only care
// about the side effect of emitting the file for Rails to reference in the view.
const images = require.context('../images', true)
const fonts = require.context('../fonts', true)

//const fontawesome = require.context('@fortawesome/fontawesome-free/webfonts')

// import behaviors
const behaviors = require.context('../src/behaviors', true)
behaviors.keys().forEach(behaviors)
