/*
 * Tempus Dominus is the successor to the very popular Eonasdan/bootstrap-datetimepicker.
 * The plugin provide a robust date and time picker designed to integrate
 * into your Bootstrap project.
 *
 * Bootstrap4 v5 Docs: https://tempusdominus.github.io/bootstrap-4/Usage/
 *     https://tempusdominus.github.io/bootstrap-4/Functions/
 *     https://tempusdominus.github.io/bootstrap-4/Options/
 *     https://tempusdominus.github.io/bootstrap-4/Events/
 */

import $ from 'jquery'
import 'tempusdominus-bootstrap-4'

'use strict';

// set icons to use Font Awesome 5
$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
  icons: {
    time: 'fas fa-clock',
    date: 'fas fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'fas fa-trash',
    close: 'fas fa-times'
  },
  buttons: {
    showToday: true,
    showClear: true,
    showClose: true
  },
  keepOpen: true,
  useCurrent: false,
  sideBySide: true,
  toolbarPlacement: 'bottom',
  allowInputToggle: true,
  stepping: 5
})
import '../locales/tempusdominus-bootstrap-4-defaults-de'

(function() {

  // we need some additional attributes set before we can initialize
  const pre_init_datepicker = function(el) {
    let $this   = $(el),
        $input  = $this.find('.datetimepicker-input'),
        $append = $this.find('.input-group-append'),
        id      = $input.attr('id')+'_input'
    $this.attr('data-target-input', 'nearest')
    $this.attr('id', id)
    $input.attr('data-target', '#'+id)
    $append.attr('data-target', '#'+id)
    $append.attr('data-toggle', 'datetimepicker')
  }

  const initializeDateTimePicker = function () {
    $('.js-default-datetimepicker-init').each(function() {
      // construct data attributes before initialization
      pre_init_datepicker(this)
      // now we can initialize
      $(this).datetimepicker()
    })
    $('.js-default-datepicker-init').each(function() {
      // construct data attributes before initialization
      pre_init_datepicker(this)
      // now we can initialize
      $(this).datetimepicker({
        format: 'L'
        // keeps date picker to stay open after blur event
        //debug: true
      })
    })
    $('.js-default-timepicker-init').each(function() {
      // construct data attributes before initialization
      pre_init_datepicker(this)
      // now we can initialize
      $(this).datetimepicker({
        format: 'LT'
        // keeps date picker to stay open after blur event
        //debug: true
      })
    })
    $('.js-default-birthdatepicker-init').each(function() {
      // construct data attributes before initialization
      pre_init_datepicker(this)
      // now we can initialize
      $(this).datetimepicker({
        format: 'L',
        viewMode: 'years'
        // keeps date picker to stay open after blur event
        //debug: true
      })
    })
  }

  'ready show.bs page:change turbolinks:load siteware:domchanged'.split(' ').forEach((ev) => {
    document.addEventListener(ev, initializeDateTimePicker)
  })

})()
