$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
  locale: 'de-at',
  tooltips: {
    today: 'Heutiges Datum auswählen',
    clear: 'Auswahl löschen',
    close: 'Auswahlfenster schließen',
    selectMonth: 'Diesen Monat auswählen',
    prevMonth: 'Vorheriger Monat',
    nextMonth: 'Nächster Monat',
    selectYear: 'Dieses Jahr auswählen',
    prevYear: 'Voriges Jahr',
    nextYear: 'Nächstes Jahr',
    selectDecade: 'Dieses Jahrzehnt auswählen',
    prevDecade: 'Voriges Jahrzehnt',
    nextDecade: 'Nächstes Jahrzehnt',
    prevCentury: 'Voriges Jahrhundert',
    nextCentury: 'Nächstes Jahrhundert',
    pickHour: 'Diese Stunde auswählen',
    incrementHour: 'Stunden erhöhen',
    decrementHour: 'Stunden verringern',
    pickMinute: 'Diese Minuten auswählen',
    incrementMinute: 'Minuten erhöhen',
    decrementMinute: 'Minuten verringern',
    pickSecond: 'Diese Sekunden auswählen',
    incrementSecond: 'Sekunden erhöhen',
    decrementSecond: 'Sekunden verringern',
    togglePeriod: 'Zwischen Vormittag und Nachmittag umschalten',
    selectTime: 'Zwischen Zeit- und Datumsauswahl umschalten'
  }
});
