//
// fullpage.js
// Theme module
//
import $ from 'jquery'
// eslint-disable-next-line
import scrollOverflow from 'fullpage.js/vendors/scrolloverflow'
// eslint-disable-next-line
import fullpage from 'fullpage.js'

'use strict';

(function() {

  // Variables
  // =========

  var fpWrapper = document.querySelector('.fp-wrapper');

  // Methods
  // =======

  function init(fpWrapper) {

    new fullpage(fpWrapper, {

      //'$': $,

      // License
      licenseKey: 'CA9DA262-9BD6447E-B1BF8C11-D1480312',

      // Navigation
      //anchors: ['hero', 'about-1', 'about-2', 'services', 'promo', 'testimonials', 'pricing'],
      // we use data-anchor attributes instead
      // ATTENTION: they have to be unique an cannot be used
      // in any other id or name attribute on the same page

      // Custom selectors
      sectionSelector: '.fp-section',

      // Scrolling
      scrollingSpeed: 700,
      easingcss3: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
      scrollOverflow: true,

      // Design
      responsiveWidth: 768,

      // Events
      afterLoad: function(origin, destination, direction) {
        var event = new CustomEvent('fpAfterLoad', {'detail': {
          origin: origin,
          destination: destination,
          direction: direction
        }});

        window.dispatchEvent(event);
      },
      onLeave: function(origin, destination, direction) {
        var event = new CustomEvent('fpOnLeave', {'detail': {
          origin: origin,
          destination: destination,
          direction: direction
        }});
        
        window.dispatchEvent(event);
      }
    });
  }

  // Events
  // ======

  if ( fpWrapper ) {
    // fullpage does not really behave well with Turbolinks!
    // disable Turbolinks when using fullpage
    document.getElementsByTagName('body')[0].setAttribute('data-turbolinks', false);
    init(fpWrapper);
  }

})();
