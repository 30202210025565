import $ from 'jquery'

'use strict';

(function() {

  'ready show.bs page:change turbolinks:load siteware:domchanged'.split(' ').forEach((ev) => {
    document.addEventListener(ev, function () {
      $('[data-js-delayed-fade-out=queued]').each(function() {
        let $this = $(this)
        $this.delay(7000).fadeOut(3000)
        $this.data('js-delayed-fade-out', 'done')
      })
    })
  })

})();
